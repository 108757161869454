import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { DocWrapper } from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DocWrapper;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO lang="en" title="Teamwork App: Privacy Policy" mdxType="SEO" />
    <h2>{`Privacy Policy`}</h2>
    <p>{`Last Updated: 10 Jan, 2019`}</p>
    <h3>{`GENERAL`}</h3>
    <p>{`TalkBox Teamwork (“Services”) makes you easy to communicate with your company, team, or organization. Your privacy and security are our top priority. You can choose what to say and share with your members in TalkBox Teamwork, but we want you to know that no one outside of your team will be able to access that private and sensitive information. Only you and authorized employee of your organization can access your personal and traffic data.`}</p>
    <p>{`TalkBox Limited ("TalkBox") respects the privacy of its users. This document explains how TalkBox will use the information that you provide to us via the Services. TalkBox may amend this Privacy Policy from time to time. The revised Privacy Policy will be posted on the Websites.`}</p>
    <h3>{`INFORMATION COLLECTED`}</h3>
    <h4>{`Web Site`}</h4>
    <p>{`The TalkBox Teamwork web messenger and TalkBox Teamwork web console track basic information about their visitors which includes, but is not limited to IP addresses, Internet browser and operating system type, timestamps, and referring pages when you visit. TalkBox does not use any of the said information to identify specific users, but rather for administration, maintenance, service improvement, and aggregate analytics purposes. We also use cookies and similar local storage technologies. This information can be read by website when visiting it again at a later date. We use cookies in order to maintain your session when you use our services. You may de-activate cookies in most internet browsers if you have any concern.`}</p>
    <h4>{`TalkBox Teamwork Application`}</h4>
    <p>{`TalkBox collects some of your personal identifying data to identify you to the Services and connect you with other users. It includes but not limited to:`}</p>
    <ul>
      <li parentName="ul">{`Identification data (e.g. User-name, password, email addresses)`}</li>
      <li parentName="ul">{`Your current country and location; - Your location is obtained when you send out your location as instant message. This information is encrypted as normal message (see point 4 below)`}</li>
      <li parentName="ul">{`Your device information including model no, OS version, device unique identifier. – These information is obtained for anonymous analysis to improve app performance only.`}</li>
      <li parentName="ul">{`Traffic data (data processed for the purpose of the transmission of voice recordings, photo and location data); - This information is encrypted and we implement appropriate technical and organizational measures to protect it according to the provisions in privacy policy.`}</li>
      <li parentName="ul">{`Use of your device's GPS feature allows the integration with Google Maps to share your location with other users; and`}</li>
      <li parentName="ul">{`Contents of instant messaging communication. TalkBox does not use any of the said information to personally identify specific users, but rather for administration, maintenance, service improvement, and aggregate analytics purposes.`}</li>
      <li parentName="ul">{`User- Generated Content – You may add profile information which include your profile picture, display name and your own description. This will be showed to other users who search you by typing your name. Please note that the profile information and user-generated content you share with others can be copied, stored, or disseminated by other users. We encourage you to be very attentive and cautious when you generate and share your personal information or content with others.`}</li>
      <li parentName="ul">{`TalkBox will not share your name, password, email addresses, phone numbers, geolocation data, regional data, device data, voice recordings or multimedia messages with third-parties, other than those users of the service with which you communicate, unless with your express permission or consent as required by law. In addition, authentication data is encrypted with SSL when transmitted over the Internet to avoid interception by malicious users. By using the service, you agree that you are solely responsible for the information you share with other users of the system.`}</li>
    </ul>
    <h4>{`Your Personal Information`}</h4>
    <p>{`Your email address may be used for any password or account recovery. TalkBox may also send you feedback on new TalkBox news and features. You can also choose to opt-out of these messages by sending an email to `}<a parentName="p" {...{
        "href": "mailto:opt-out@talkboxapp.com"
      }}>{`opt-out@talkboxapp.com`}</a>{` from the email account you'd like to remove from our messaging services. You are also free to use a contact with a non-primary, "throwaway" email account - but definitely use one that you yourself own to avoid user collisions.`}</p>
    <p>{`The application will allow you to search other users by Talkbox Teamwork user name within your organization. Contact List between users are controlled by your organization. The role and authority of users are set up in the administration console operated by your organization.`}</p>
    <h4>{`Voice and Multimedia Messages`}</h4>
    <p>{`The TalkBox Teamwork allows you to receive and transmit voice and multimedia messages ("messages") from person to person. As such, the messages are transmitted between your device and TalkBox Teamwork servers. By using the Services, you agree to receive and transmit messages and that you have the consent of others users for you to record and upload, and to comply with any jurisdictional local laws regarding the recording or copying of others. You and your contacts' messages are stored in memory on the server until you and any other recipients successfully download the messages to their devices, at which time they are scheduled to be deleted from the TalkBox Teamwork servers. The ownership of the messages will rest with the maker of the same at all times.`}</p>
    <p>{`Please be advised that you transmit any messages to contacts at your own risk. TalkBox cannot control the behavior of contacts with whom you choose to share voice, multimedia data or location information and therefore are not responsible for any dissemination that occurs thereby.`}</p>
    <h4>{`Your Location Information`}</h4>
    <p>{`If you use the TalkBox Teamwork geolocation feature to share your location information with contacts, your GPS coordinates at the time of use are also sent to TalkBox Teamwork. Note that, if you do not use the geolocation feature, TalkBox Teamwork system does not access your location data. On iOS devices, the first time you attempt to use the Services geolocation feature, you will be prompted to approve or deny TalkBox Teamwork access to your device's GPS functionality.`}</p>
    <p>{`On Android devices, this permission is granted at installation, but TalkBox Teamwork will not turn on the GPS unless and until you enable the geo-tag function in conversation page. Make sure that you have verified and fully trust the party with whom you share your location information before using the Maps page to send a message. TalkBox Teammwork are not responsible for any inappropriate sharing of location or any damages that may be caused by such disclosure. To avoid sharing location data, do not enable the geo-tag function in the application and do not send messages while the geo-tag button is enabled.`}</p>
    <p>{`For iOS devices user: if you have granted TalkBox Teamwork access to your GPS functionality and later want to revoke it, on your mobile device, visit Settings > General > Reset and click Reset Location Warnings. The next time you use TalkBox Teamwork and enable the geo-tag function, you will again be prompted to allow access to Location and can click Don't Allow (note that you can also disable Location Services globally for all applications by setting Settings > General > Location Services to the "Off" position).`}</p>
    <p>{`TalkBox Teamwork does not store your GPS coordinates permanently on its servers; like your voice messages, they are ephemeral and exist in memory on TalkBox Teamwork systems only until you and your recipient(s) have successfully exchanged messages. Note that GPS locations are stored on the mobile device, so that if you transmit a message on the Conversation screen with Geo-tag button enabled, and then disable it, your contact may still see your sent location data.`}</p>
    <p>{`Please noted that all personal information; users contact list and the contents generated by users may stored in servers if your organization is using private cloud server or server provided by your organization. Please check with your own organization for its selection. It is your own organization policy to determine how long to keep the data.`}</p>
    <h3>{`USES OF COLLECTED INFORMATION`}</h3>
    <h4>{`How We Use the Information We Collect`}</h4>
    <p>{`We use information collected through TalkBox Teamwork services in variety of ways in operating our business. We will ensure the use of information is within the user agreement with you, or is with your consent, or is in our legitimate interest of providing TalkBox Teamwork service. The use of information includes the following:`}</p>
    <ul>
      <li parentName="ul">{`We use the information that we collected through TalkBox Teamwork to operate, maintain, enhance and provide features of TalkBox Teamwork, to provide service and information you requested.`}</li>
      <li parentName="ul">{`We use the information that we collected through TalkBox Teamwork to analyze the usage trends and preferences of our users, to improve our TalkBox Teamwork and to develop new feature or new product.`}</li>
      <li parentName="ul">{`We may use your information (i.e. email) to contact you for administrative purposes, including customer service only.`}</li>
    </ul>
    <h4>{`When We Disclose Information`}</h4>
    <p>{`We will not disclose your information that we collect through TalkBox Teamwork to third parties except as described in this Policy or as we disclosed at the time the information is collected. We may disclose information to third parties in the following circumstances:`}</p>
    <ul>
      <li parentName="ul">{`The information you choose to share to other TalkBox Teamwork users, including the display name, profile picture, self description will be viewed or searched by other TalkBox teamwork users at TalkBox Teamwork within your organization.`}</li>
      <li parentName="ul">{`We may preserve and disclose your information if required to do so by law or in such action is necessary to comply with applicable laws, in response to a court order, judicial or other government subpoena, warrant or request, or to otherwise cooperate with law enforcement or other governmental agencies.`}</li>
      <li parentName="ul">{`We reserve the right to preserve and disclose your information that we believe is appropriate or necessary to (a) take precautions against liability; (b) protect ourselves or others from fraudulent, abusive, or unlawful uses or activities, or suspected illegal acts; (c) investigate and defend ourselves against any third-party claims or allegations; (d) protect our property or other legal rights (including, but not limited to, enforcement of our agreements), or the rights, property, or safety of others; (e) protect vital interests of yours or of another individual.`}</li>
    </ul>
    <h3>{`RIGHTS REGARDING YOUR INFORMATION`}</h3>
    <h4>{`Your Rights Regarding Your Information`}</h4>
    <ul>
      <li parentName="ul">{`You can access and update your information on TalkBox Teamwork application. Subject to applicable law, you may have right to request access to additional information we maintain about you. You also have the right to object to the processing of your information, to withdraw your consent to the processing of your information, or to be “forgotten”. Please note if you exercise the right to object to process or provide certain information about you, we may not be able to provide you some, or any, of the features and functionalities of TalkBox Teamwork Service.`}</li>
      <li parentName="ul">{`If you wish to access or amend any other information we maintain about you, you may contact us at `}<a parentName="li" {...{
          "href": "mailto:privacy@talkboxapp.com"
        }}>{`privacy@talkboxapp.com`}</a></li>
      <li parentName="ul">{`If you wish to unsubscribe any email or text message from us, you may contact us at `}<a parentName="li" {...{
          "href": "mailto:opt-out@talkboxapp.com"
        }}>{`opt-out@talkboxapp.com`}</a></li>
    </ul>
    <h3>{`DATA SECURITY`}</h3>
    <p>{`alkBox is dedicated to secure customer data and, to that end, TalkBox employs high standard security practices to keep your data protected. As part of the effort to keep your data protected, all personal and contact information transmitted through the TalkBox Teamwork application is encrypted with SSL connection using AES-128 bit encryption between your mobile device and the TalkBox Teamwork servers. That said, there are a number of risks in transmitting any kind of data over the public Internet and allowing voice recordings to reside on a physical device, so under no circumstances should you use the TalkBox Teamwork to transmit confidential or privileged information of any sort.`}</p>
    <h4>{`PROTECT OF YOUR PERSONAL INFORMATION`}</h4>
    <p>{`TalkBox shall take appropriate organizational and technical measures to protect the personal data and traffic data provided to it or collected by it with due observance of the applicable obligations and exceptions under the relevant legislation. Your personal and traffic data can only be accessed by authorized employees or consultants of TalkBox that need to have access this data in order to be able to fulfill their given duties.`}</p>
    <h4>{`International Transfers of Personal Data`}</h4>
    <p>{`TalkBox Teamwork Service connect to servers host in Hong Kong, Taiwan and Singapore. If you choose to use TalkBox Teamwork Service from the European Union(EU), please understand that your information will be transferred outside of EU for processing and storage. If you are in the European Economic Area, please also refer to our GDPR commitment`}</p>
    <h4>{`Retention of Your Information`}</h4>
    <p>{`We retain your information to provide TalkBox Teamwork Service to you, or as required by applicable laws and regulations.`}</p>
    <h3>{`POLICY CHANGES AND UPDATES`}</h3>
    <h4>{`Changes and Updates to this Policy`}</h4>
    <p>{`TalkBox may update this policy from time to time and as products are updated and new product features offering are available. TalkBox will publish any changes on website and notify the changes when the Privacy Policy is updated. TalkBox will not share personally-identifiable information with third parties without your consent unless required by law or provisions according to this privacy policy.`}</p>
    <h3>{`Our Contact Information`}</h3>
    <p>{`If you have questions, suggestions, or wish to make a complaint, please contact us at:`}</p>
    <Card mt="l" mdxType="Card">
  <h5>Talkbox Limited</h5>
  <p fontSize="s">
    20/F., Harbourside HQ
    <br />
    8 Lam Chak Street,
    <br />
    Kowloon Bay,
    <br />
    Hong Kong
    <br />
    <br />
    Email: privacy@talkboxapp.com
  </p>
    </Card>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      